import { render, staticRenderFns } from "./_articleSlug.vue?vue&type=template&id=b36bf86e&scoped=true&"
import script from "./_articleSlug.vue?vue&type=script&lang=js&"
export * from "./_articleSlug.vue?vue&type=script&lang=js&"
import style0 from "./_articleSlug.vue?vue&type=style&index=0&id=b36bf86e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b36bf86e",
  null
  
)

/* custom blocks */
import block0 from "./_articleSlug.vue?vue&type=custom&index=0&blockType=route"
if (typeof block0 === 'function') block0(component)

export default component.exports